<template>
    <div :class="['layout-inline-menu', {'layout-inline-menu-active': activeInlineProfile}]" v-if="!isMobile() && (isStatic() || isSlim() || isSidebar())">
        <a class="layout-inline-menu-action" @click="onChangeActiveInlineMenu">
           
            <span class="layout-inline-menu-text">{{ nome }} {{ cognome }}</span>
            <i class="layout-inline-menu-icon pi pi-angle-down"></i>
        </a>

        <transition name="layout-inline-menu-action-panel">
            <ul v-show="activeInlineProfile" class="layout-inline-menu-action-panel">
                <li class="layout-inline-menu-action-item" @click="logout">
                    <a>
                        <i class="pi pi-power-off pi-fw"></i>
                        <span>Logout</span>
                    </a>
                </li>
                
            </ul>
        </transition>
    </div>
</template>

<script>
import VueJwtDecode from 'vue-jwt-decode';
const { DateTime } = require('luxon');
	export default {
        name: 'AppInlineMenu',
        emits: ['change-inlinemenu'],
        props: {
            menuMode: String,
            activeInlineProfile: {
                type: Boolean,
                default: false
            }
        },
        computed: {
            token() {
                const token = localStorage.getItem('token')
                if(token) return VueJwtDecode.decode(localStorage.getItem('token'))
                return null
            },
            nickname() {
                return this.token?.nickname;
            },
            nome() {
                return this.token?.nome;
            },
            cognome() {
                return this.token?.cognome;
            },
        },
        created() {
            //Check token expiration
            setInterval(()=> {
                if(!this.token) return;
                const s = VueJwtDecode.decode(localStorage.getItem('token')).exp;
                const expirationTime = DateTime.fromSeconds(s)
                const now = DateTime.now();
                if(expirationTime.minus({ minutes: 5 }) < now) this.logout();
            },10000)
        },
        methods: {
            onChangeActiveInlineMenu(event) {
                this.$emit('change-inlinemenu', event);
                event.preventDefault();
            },
            isHorizontal() {
                return this.menuMode === 'horizontal';
            },
            isSlim() {
                return this.menuMode === 'slim';
            },
            isOverlay() {
                return this.menuMode === 'overlay';
            },
            isStatic() {
                return this.menuMode === 'static';
            },
            isSidebar() {
                return this.menuMode === 'sidebar';
            },
            isDesktop() {
                return window.innerWidth > 991;
            },
            isMobile() {
                return window.innerWidth <= 991;
            },
            logout() {
                localStorage.removeItem('token');
                this.$router.replace({ path: '/login'});
            }
        }
	}
</script>

<style scoped>

</style>
