<template>
    <div :class="layoutContainerClass" @click="onDocumentClick">
        <div class="layout-main">
            <AppTopBar
                v-show="$router.currentRoute.value.fullPath !== '/listeapertemartina' && $router.currentRoute.value.fullPath !== '/consegne'"
                :items="menu" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive"
                :topbarMenuActive="topbarMenuActive" :activeInlineProfile="activeInlineProfile"
                @topbar-item-click="onTopbarItemClick" @menubutton-click="onMenuButtonClick"
                @sidebar-mouse-over="onSidebarMouseOver" @sidebar-mouse-leave="onSidebarMouseLeave"
                @toggle-menu="onToggleMenu" @change-inlinemenu="onChangeActiveInlineMenu" @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

            <AppMenu
                v-show="$router.currentRoute.value.fullPath !== '/listeapertemartina' && $router.currentRoute.value.fullPath !== '/consegne'"
                :model="menu" :menuMode="menuMode" :colorScheme="colorScheme" :menuActive="menuActive"
                :sidebarActive="sidebarActive" :sidebarStatic="sidebarStatic" :pinActive="pinActive"
                :activeInlineProfile="activeInlineProfile" @sidebar-mouse-over="onSidebarMouseOver"
                @sidebar-mouse-leave="onSidebarMouseLeave" @toggle-menu="onToggleMenu"
                @change-inlinemenu="onChangeActiveInlineMenu" @menu-click="onMenuClick"
                @menuitem-click="onMenuItemClick" @root-menuitem-click="onRootMenuItemClick" />

            <!-- <AppBreadcrumb
        :menuMode="menuMode"
        v-model:searchActive="searchActive"
        v-model:searchClick="searchClick"
        @menubutton-click="onMenuButtonClick"
        @rightmenu-button-click="onRightMenuButtonClick"
      /> -->

            <div class="layout-main-content">
                <router-view />
            </div>

            <AppFooter :colorScheme="colorScheme" />
        </div>

        <AppRightMenu :rightMenuActive="rightMenuActive" @rightmenu-click="onRightMenuClick"
            @rightmenu-active="onChangeRightMenuActive" />

        <AppConfig
            v-show="$router.currentRoute.value.fullPath !== '/listeapertemartina' && $router.currentRoute.value.fullPath !== '/consegne'"
            v-model:menuMode="menuMode" :theme="theme" :componentTheme="componentTheme" :colorScheme="colorScheme"
            :configActive="configActive" :configClick="configClick" @config-button-click="onConfigButtonClick"
            @config-click="onConfigClick" @change-color-scheme="onChangeColorScheme"
            @change-component-theme="onChangeComponentTheme" @change-menu-theme="onChangeMenuTheme"
            @change-menu-mode="onChangeMenuMode" />
    </div>
</template>

<script>
    import AppTopBar from './AppTopbar.vue'
    import AppMenu from './AppMenu.vue'
    import AppRightMenu from './AppRightMenu.vue'
    import AppFooter from './AppFooter.vue'
    //import AppBreadcrumb from "./AppBreadcrumb.vue";
    import AppConfig from './AppConfig.vue'
    import EventBus from './event-bus'
    import VueJwtDecode from 'vue-jwt-decode'
    import routePermissionsMap from './routePermissionsMap'

    export default {
        emits: ['layout-mode-change', 'menu-theme', 'layoutModeChange', 'sidebar-mouse-over', 'sidebar-mouse-leave', 'change-color-scheme', 'change-component-theme', 'change-menu-theme', 'change-inlinemenu'],
        props: {
            colorScheme: String,
            topbarTheme: String,
            theme: String,
            componentTheme: String,
        },
        data() {
            return {
                search: false,
                searchClick: false,
                searchActive: false,
                menuMode: 'overlay',
                menuActive: false,
                activeInlineProfile: false,
                inlineMenuClick: false,
                overlayMenuActive: false,
                topbarMenuActive: false,
                topbarItemClick: false,
                sidebarActive: false,
                sidebarStatic: false,
                pinActive: false,
                staticMenuDesktopInactive: false,
                staticMenuMobileActive: false,
                rightMenuClick: false,
                rightMenuActive: false,
                configActive: false,
                configClick: false,
                menu: [
                    {
                        label: 'Cash Flow',
                        icon: 'pi pi-fw pi-dollar',
                        items: [
                            {
                                label: 'Budget Cash Flow',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/budgetcashflow',
                            },
                            {
                                label: 'Flussi Bancari',
                                icon: 'pi pi-fw pi-building',
                                to: '/flussibancari',
                            },
                            {
                                label: 'Anticipo Riba',
                                icon: 'pi pi-fw pi-building',
                                to: '/anticipobancheriba',
                            },
                            
                            {
                                label: 'Approva Fatture Acquisti',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/autorizzafattureacquisti',
                            },
                            {
                                label: 'Approva Ordini Acquisti',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/autorizzaordiniacquisti',
                            },
                            {
                                label: 'Ordini Aperti Fornitore',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/statoordinifornitoriconsegne',
                            },

                            {
                                label: 'Ordini Aperti Clienti',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/ordiniaperticlientiperdocumentocashflow',
                            },
                            {
                                label: 'Scadenze Aperte Fornitore',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/scadenzefinemesefornitore',
                            },
                            {
                                label: 'Scadenze AJAX e Budget',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/scadenzarioajax',
                            },
                            
                            {
                                label: 'Costi Previsti',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/costiprevisti',
                            },

                            {
                                label: 'Centro Di Costo',
                                icon: 'pi pi-fw pi-chart-bar',
                                to: '/centrodicosto',
                            },

                            {
                                label: 'Calcolo Iva',
                                icon: 'pi pi-fw pi-euro',
                                to: '/calcoloiva',
                            },
                        ],
                    },
                    {
                        label: 'Amministrazione',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Sospesi per Agente',
                                icon: 'pi pi-fw pi-euro',
                                to: '/sospesiperagente',
                            },
                            {
                                label: 'Saldi Banche',
                                icon: 'pi pi-fw pi-building',
                                to: '/saldibanche',
                            },
                            {
                                label: 'Match Scadenze Excel',
                                icon: 'pi pi-fw pi-building',
                                to: '/matchscadenzeexcel',
                            },
                            
                            {
                                label: 'Calcolo Costo Ritardo Pagamento',
                                icon: 'pi pi-fw pi-building',
                                to: '/calcolocostodso',
                            },
                            {
                                label: 'Calcolo Costo DSO Ideale',
                                icon: 'pi pi-fw pi-building',
                                to: '/calcolocostodsoideale',
                            },
                            {
                                label: 'Finanziamenti',
                                icon: 'pi pi-fw pi-euro',
                                items: [
                                    {
                                        label: 'Finanziamenti',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/finanziamenti',
                                    },
                                    {
                                        label: 'Manutenzione Finanziamenti',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/manutenzionefinanziamenti',
                                    },
                                ],
                            },
                            {
                                label: 'Scadenze Ricorrenti',
                                icon: 'pi pi-fw pi-euro',
                                items: [
                                    {
                                        label: 'Crea Scadenza Ricorrente',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/creascadenzaricorrente',
                                    },
                                    {
                                        label: 'Pagamenti Ricorrenti',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/pagamentiricorrenti',
                                    },
                                ],
                            },

                            {
                                label: 'Manutenzione',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Manutenzione Banche',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/manutenzionebanche',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Stato Fido Clienti',
                        icon: 'pi pi-fw pi-desktop',
                        items: [
                            {
                                label: 'Stato Fidi Clienti',
                                icon: 'pi pi-fw pi-user-edit',
                                to: '/statofidiclienti',
                            },
                        ],
                    },

                    {
                        label: 'Manutenzioni',
                        icon: 'pi pi-fw pi-desktop',
                        items: [
                            {
                                label: 'Gestione dei permessi',
                                icon: 'pi pi-fw pi-user-edit',
                                to: '/gestionepermessi',
                            },
                            {
                                label: 'Dialog Commenti',
                                icon: 'pi pi-fw pi-comment',
                                to: '/dialogcommenti',
                            },
                        ],
                    },
                    {
                        label: 'Anagrafiche',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Clienti',
                                icon: 'pi pi-fw pi-user',
                                items: [
                                    {
                                        label: 'Anagrafiche Clienti',
                                        icon: 'pi pi-fw pi-user',
                                        to: '/clienti',
                                    },
                                    {
                                        label: 'Inserisci Anagrafiche Clienti',
                                        icon: 'pi pi-fw pi-user',
                                        to: '/inseriscianagraficheclienti',
                                    }
                                ],
                            },
                            {
                                label: 'Fornitori',
                                icon: 'pi pi-fw pi-briefcase',
                                items: [
                                    {
                                        label: 'Anagrafiche Fornitori',
                                        icon: 'pi pi-fw pi-briefcase',
                                        to: '/fornitori',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Missioni dipendenti',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Missioni',
                                icon: 'pi pi-fw pi-user',
                                items: [
                                    {
                                        label: 'Inserisci Missioni',
                                        icon: 'pi pi-fw pi-user',
                                        to: '/inserimentomissioni',
                                    },
                                ],
                            },
                            {
                                label: 'Tabelle',
                                icon: 'pi pi-fw pi-briefcase',
                                items: [
                                    {
                                        label: 'Manutenzione Parco Auto',
                                        icon: 'pi pi-fw pi-briefcase',
                                        to: '/parcoauto',
                                    },
                                    {
                                        label: 'Scheda Tratte',
                                        icon: 'pi pi-fw pi-briefcase',
                                        to: '/schedetratte',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Permessi e Ferie',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Permessi e Ferie',
                                icon: 'pi pi-fw pi-user',
                                items: [
                                    {
                                        label: 'Richiesta permessi e Ferie',
                                        icon: 'pi pi-fw pi-user',
                                        to: '/richiestapermessieferie',
                                    },
                                    {
                                        label: 'Approvazione permessi e Ferie',
                                        icon: 'pi pi-fw pi-user',
                                        to: '/approvezionepermessieferie',
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        label: 'Check Matricole',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Check Matricole',
                                icon: 'pi pi-fw pi-user',
                                to: '/checkmatricole',
                            },
                        ],
                    },
                    {
                        label: 'Articoli',
                        icon: 'pi pi-fw pi-cart-plus',
                        items: [
                            {
                                label: 'Articoli',
                                icon: 'pi pi-fw pi-cart-plus',
                                to: '/articolids',
                            },
                            {
                                label: 'Richieste di Articoli da DSWeb',
                                icon: 'pi pi-fw pi-cart-plus',
                                to: '/richiestediarticolidadsweb',
                            },
                        ],
                    },
                    {
                        label: 'Clienti',
                        icon: 'pi pi-fw pi-users',
                        items: [
                            {
                                label: 'Flusso Documentale',
                                icon: 'pi pi-fw pi-file',
                                to: '/anagraficheclienti',
                            },
                        ],
                    },
                    {
                        label: 'Corrieri',
                        icon: 'pi pi-fw pi-car',
                        items: [
                            {
                                label: 'Consegne',
                                icon: 'pi pi-fw pi-box',
                                to: '/consegne',
                            },

                            {
                                label: 'Vedi Corrieri',
                                icon: 'pi pi-fw pi-users',
                                to: '/corrieri',
                            },
                        ],
                    },
                    {
                        label: 'Agenti',
                        icon: 'pi pi-fw pi-briefcase',
                        items: [
                            {
                                label: 'DSO Agenti',
                                icon: 'pi pi-fw pi-file',
                                to: '/anagraficheclienti',
                            },
                            {
                                label: 'Budget Agenti Mensile',
                                icon: 'pi pi-fw pi-euro',
                                to: '/budgetagentimese',
                            },
                            {
                                label: 'Budget Agenti Trimestre',
                                icon: 'pi pi-fw pi-euro',
                                to: '/budgetagentitrimestre',
                            },
                            {
                                label: 'Budget Squadra Mensile',
                                icon: 'pi pi-fw pi-euro',
                                to: '/budgetsquadramese',
                            },
                            {
                                label: 'Budget Squadra Trimestre',
                                icon: 'pi pi-fw pi-euro',
                                to: '/budgetsquadratrimestre',
                            },
                            {
                                label: 'Stato Budget Trimestre',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/budgetstatotrimestre',
                            },
                            {
                                label: 'Stato Budget Mensile',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/budgetstatomensile',
                            },
                        ],
                    },
                    {
                        label: 'Banco',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Banco Martina Franca',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Valorizzazione Magazzino',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/consegnealbancomartinafranca',
                                    }
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Magazzini',
                        icon: 'pi pi-fw pi-building',
                        items: [
                            {
                                label: 'Magazzino Fiscale',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Valorizzazione Magazzino',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/valorizzazionimagazzino',
                                    }
                                ],
                            },
                            {
                                label: 'Martina Franca',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Ordini Evadibili Amministrazione',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/ordinievadibilimf',
                                    },
                                    {
                                        label: 'Lavorato del Giorno',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/lavoratodelgiornomf',
                                    },
                                    {
                                        label: 'Ordini Evadibili Gestore',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/ordinievadibilimfGestore',
                                    },
                                    {
                                        label: 'Liste di Prelievo Aperte',
                                        icon: 'pi pi-fw pi-shopping-cart',
                                        to: '/listeapertemartina',
                                    },
                                ],
                            },
                            {
                                label: 'Casarano',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Liste di Prelievo Aperte',
                                        icon: 'pi pi-fw pi-shopping-cart',
                                        to: '/listeapertecasarano',
                                    },
                                    {
                                        label: 'Ordini Inevasi Casarano',
                                        icon: 'pi pi-fw pi-shopping-cart',
                                        to: '/ordiniinevasicasarano',
                                    },
                                ],
                            },
                            {
                                label: 'Lecce',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Ordini Inevasi',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/ordiniinevasilecce',
                                    },
                                    {
                                        label: 'Liste di Prelievo Aperte',
                                        icon: 'pi pi-fw pi-shopping-cart',
                                        to: '/listeapertelecce',
                                    },
                                ],
                            },
                            {
                                label: 'Modugno',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Ordini Inevasi',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/ordiniinevasimodugno',
                                    },
                                    {
                                        label: 'Liste di Prelievo Aperte',
                                        icon: 'pi pi-fw pi-shopping-cart',
                                        to: '/listeapertemodugno',
                                    },
                                ],
                            },
                            {
                                label: 'Oria',
                                icon: 'pi pi-fw pi-file',
                                items: [
                                    {
                                        label: 'Ordini Inevasi',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/ordiniinevasioria',
                                    },
                                    {
                                        label: 'Liste di Prelievo Aperte',
                                        icon: 'pi pi-fw pi-shopping-cart',
                                        to: '/listeaperteoria',
                                    },
                                ],
                            },
                        ],
                    },
                    {
                        label: 'Venduto - Ciclo Attivo',
                        icon: 'pi pi-fw pi-shopping-cart',
                        items: [
                            {
                                label: 'Vendite',
                                icon: 'pi pi-fw pi-chart-line',
                                items: [
                                    {
                                        label: 'Venduto del Giorno',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/vendutodelgiorno',
                                    },
                                    {
                                        label: 'Venduto per Cliente',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/vendutopercliente',
                                    },
                                    {
                                        label: 'Venduto per Brand',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/vendutoperbrand',
                                    },
                                    {
                                        label: 'Confronto Fatturati',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/confrontofatturati',
                                    },
                                ],
                            },
                            {
                                label: 'Sospesi',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Da recuperare',
                                        icon: 'pi pi-fw pi-euro',
                                        to: '/sospesiclienti',
                                    },
                                    {
                                        label: 'Clienti da ricontattare',
                                        icon: 'pi pi-fw pi-calendar',
                                        to: '/sospesiclientidaricontattare',
                                    },
                                    {
                                        label: 'Stato Assicurazione',
                                        icon: 'pi pi-fw pi-credit-card',
                                        to: '/statoassicurazioneclienti',
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        label: 'Acquisti  - Ciclo Passivo',
                        icon: 'pi pi-fw pi-box',
                        items: [
                            {
                                label: 'Acquisti',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/acquisti',
                            },
                            {
                                label: 'Ordini Inevasi',
                                icon: 'pi pi-fw pi-chart-pie',
                                to: '/inevasofornitore',
                            },
                            {
                                label: 'Riassortimento Scorte',
                                icon: 'pi pi-fw pi-cart-plus',
                                to: '/riassortimentoscorte',
                            },
                        ],
                    },

                    {
                        label: 'Statistiche Venduto',
                        icon: 'pi pi-fw pi-box',
                        items: [
                            {
                                label: 'Venduto Direzionale',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Venduto prodotti anno corrente',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/vendutoprodottiannocorrente',
                                    },
                                    {
                                        label: 'Venduto Agenti Mensile',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/vendutoagentimensile',
                                    },
                                    {
                                        label: 'Venduto Con Incidenza',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/vendutoconincidenza',
                                    },
                                    {
                                        label: 'Storico Fatturati',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/storicovendutofatturatoagenti',
                                    },
                                    {
                                        label: 'Top 50 Clienti per Brand',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/top50clientiperbrand',
                                    },


                                ],
                            },
                            {
                                label: 'Venduto Agenti',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Fatturato per Clienti',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/fatturatoperclienti',
                                    },

                                    {
                                        label: 'Fatturato Mensile',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/fatturatomensile',
                                    },

                                    {
                                        label: 'Storico Fatturato Mensile',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/fatturatomensilemesiscorsi',
                                    },
                                    {
                                        label: 'Fatturato Pivot',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/fatturatopivot',
                                    },
                                ],
                            },
                            {
                                label: 'Venduto Agenti Fotovoltaico',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Fatturato per Clienti Fotovoltaico',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/fatturatoperclientifotovoltaico',
                                    },
                                    {
                                        label: 'Fatturato Mensile Fotovoltaico',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/fatturatomensilefotovoltaico',
                                    },
                                ],
                            },
                            {
                                label: 'Confronti',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Confronto Agente Brand',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/confrontoagentebrand',
                                    },
                                    {
                                        label: 'Confronto Agente Fotovoltaico',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/confrontoagentefotovoltaico',
                                    },
                                ],
                            },
                            {
                                label: 'Situazione Ordini',
                                icon: 'pi pi-fw pi-wallet',
                                items: [
                                    {
                                        label: 'Ordini Inevasi Clienti',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/ordiniinevasiclienti',
                                    },
                                    {
                                        label: 'Ordini Inevasi Filiali',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/ordiniinevasifiliali',
                                    },

                                    {
                                        label: 'Ordini Non Evasi Filiali',
                                        icon: 'pi pi-fw pi-chart-line',
                                        to: '/ordininonevasifiliali',
                                    },
                                ],
                            },
                        ],
                    },

                    {
                        label: 'Statistiche Generali',
                        icon: 'pi pi-fw pi-box',
                        items: [
                            {
                                label: 'Doc. Listino Variato',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/statistichedocumentiarca',
                            },
                        ],
                    },
                    {
                        label: 'Utility',
                        icon: 'pi pi-fw pi-wrench',
                        items: [
                            {
                                label: 'Stato Robottino',
                                icon: 'pi pi-fw pi-money-bill',
                                to: '/statorobottino',
                            },
                        ],
                    },
                    {
                        label: 'E/U',
                        icon: 'pi pi-fw pi-box',
                        items: [
                            {
                                label: 'Entrate',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/entrate',
                            },
                            {
                                label: 'Uscite',
                                icon: 'pi pi-fw pi-chart-pie',
                                to: '/uscite',
                            },
                            {
                                label: 'Categorie',
                                icon: 'pi pi-fw pi-chart-pie',
                                to: '/budgetcategorie',
                            },
                        ],
                    },
                    {
                        label: 'Costi - Centro di Costo',
                        icon: 'pi pi-fw pi-chart-line',
                        to: '/costi',
                    },
                    {
                        label: 'Promozioni',
                        icon: 'pi pi-fw pi-folder-open',
                        items: [
                            {
                                label: 'Listini Ribassati',
                                icon: 'pi pi-fw pi-folder-open',
                                to: '/listiniribassati',
                            },
                            {
                                label: 'Import Promo',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/importpromo',
                            },
                        ],
                    },
                    {
                        label: 'Incassi',
                        icon: 'pi pi-fw pi-wallet',
                        items: [
                            {
                                label: 'Wizard Incassi',
                                icon: 'pi pi-fw pi-euro',
                                to: '/indexincassi',
                            },
                            {
                                label: 'Wizard Incassi Globale',
                                icon: 'pi pi-fw pi-euro',
                                to: '/incassibanconista',
                            },
                            {
                                label: 'Portafoglio Agenti',
                                icon: 'pi pi-fw pi-wrench',
                                to: '/portafoglioagenti',
                            },

                            {
                                label: 'Portafoglio Banconisti',
                                icon: 'pi pi-fw pi-wrench',
                                to: '/portafoglioagentibanco',
                            },

                            {
                                label: 'Portafoglio Acconti',
                                icon: 'pi pi-fw pi-wrench',
                                to: '/portafoglioaccontiagenti',
                            },

                            {
                                label: 'Manutenzione Incassi',
                                icon: 'pi pi-fw pi-wallet',
                                to: '/manutenzioneincassi',
                            },

                            {
                                label: 'Manutenzione Acconti',
                                icon: 'pi pi-fw pi-wallet',
                                to: '/manutenzioneacconti',
                            },
                        ],
                    },

                    {
                        label: 'Riparazioni',
                        icon: 'pi pi-fw pi-wrench',
                        items: [
                            {
                                label: 'Storico Riparazioni',
                                icon: 'pi pi-fw pi-ticket',
                                to: '/riparazioni',
                            },
                            {
                                label: 'Situazione Fornitori',
                                icon: 'pi pi-fw pi-ticket',
                                to: '/situazionefornitori',
                            },
                            {
                                label: 'Valorizzazione riparazioni per Fornitore',
                                icon: 'pi pi-fw pi-chart-line',
                                to: '/statisticheriparazioni',
                            },

                            {
                                label: 'Flow Riparazioni',
                                icon: 'pi pi-fw pi-ticket',
                                to: '/flowriparazioni',
                            },
                            {
                                label: 'Table Demo',
                                icon: 'pi pi-fw pi-ticket',
                                to: '/tabledemo',
                            },
                        ],
                    },

                    {
                        label: 'Demo',
                        icon: 'pi pi-fw pi-wrench',
                        items: [
                            {
                                label: 'Presa in Carico',
                                icon: 'pi pi-fw pi-ticket',
                                to: '/presaincaricoriparazione',
                            },
                            {
                                label: 'Table Demo',
                                icon: 'pi pi-fw pi-ticket',
                                to: '/tabledemo',
                            },
                        ],
                    },
                ],
                routePermissionsMap
            }
        },
        watch: {
            $route() {
                this.menuActive = false
                this.$toast.removeAllGroups()
            },
        },
        methods: {
            onDocumentClick() {
                if (!this.searchClick && this.searchActive) {
                    this.onSearchHide()
                }

                if (!this.topbarItemClick) {
                    this.topbarMenuActive = false
                }

                if (!this.menuClick) {
                    if (this.isHorizontal() || this.isSlim()) {
                        this.menuActive = false
                        EventBus.emit('reset-active-index')
                    }

                    if (this.overlayMenuActive || this.staticMenuMobileActive) {
                        this.overlayMenuActive = false
                        this.staticMenuMobileActive = false
                    }

                    this.hideOverlayMenu()
                    this.unblockBodyScroll()
                }

                if (!this.rightMenuClick) {
                    this.rightMenuActive = false
                }

                if (this.configActive && !this.configClick) {
                    this.configActive = false
                }

                this.topbarItemClick = false
                this.menuClick = false
                this.configClick = false
                this.rightMenuClick = false
                this.searchClick = false
                this.inlineMenuClick = false
            },
            onSearchHide() {
                this.searchActive = false
                this.searchClick = false
            },
            onSidebarMouseOver() {
                if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                    this.sidebarActive = this.isDesktop()
                    setTimeout(() => {
                        this.pinActive = this.isDesktop()
                    }, 200)
                }
            },
            onSidebarMouseLeave() {
                if (this.menuMode === 'sidebar' && !this.sidebarStatic) {
                    setTimeout(() => {
                        this.sidebarActive = false
                        this.pinActive = false
                    }, 250)
                }
            },
            hideOverlayMenu() {
                this.overlayMenuActive = false
                this.staticMenuMobileActive = false
            },
            onMenuButtonClick(event) {
                this.menuClick = true

                if (this.isOverlay()) {
                    this.overlayMenuActive = !this.overlayMenuActive
                }

                if (this.isDesktop()) {
                    this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive
                } else {
                    this.staticMenuMobileActive = !this.staticMenuMobileActive
                }

                event.preventDefault()
            },
            onTopbarItemClick(event) {
                this.topbarItemClick = true
                this.topbarMenuActive = !this.topbarMenuActive
                this.hideOverlayMenu()
                event.preventDefault()
            },
            onRightMenuButtonClick() {
                this.rightMenuClick = true
                this.rightMenuActive = true
            },
            onRightMenuClick() {
                this.rightMenuClick = true
            },
            onMenuClick() {
                this.menuClick = true
            },
            onRootMenuItemClick() {
                this.menuActive = !this.menuActive
            },
            onMenuItemClick(event) {
                if (!event.item.items) {
                    this.hideOverlayMenu()
                    EventBus.emit('reset-active-index')
                }

                if (!event.item.items && (this.isHorizontal() || this.isSlim())) {
                    this.menuActive = false
                }
            },
            onChangeMenuMode(menuMode) {
                this.menuMode = menuMode
                this.overlayMenuActive = false
            },
            onConfigClick() {
                this.configClick = true
            },
            onConfigButtonClick(event) {
                this.configActive = !this.configActive
                this.configClick = true
                event.preventDefault()
            },
            onChangeRightMenuActive(active) {
                this.rightMenuActive = active
            },
            onChangeMenuTheme(theme) {
                this.$emit('change-menu-theme', theme)
            },
            onChangeComponentTheme(theme) {
                this.$emit('change-component-theme', theme)
            },
            onChangeColorScheme(scheme) {
                this.$emit('change-color-scheme', scheme)
            },
            onToggleMenu(event) {
                this.menuClick = true

                if (this.overlayMenuActive) {
                    this.overlayMenuActive = false
                }

                if (this.sidebarActive) {
                    this.sidebarStatic = !this.sidebarStatic
                }

                event.preventDefault()
            },
            onChangeActiveInlineMenu() {
                this.activeInlineProfile = !this.activeInlineProfile
            },
            blockBodyScroll() {
                if (document.body.classList) {
                    document.body.classList.add('blocked-scroll')
                } else {
                    document.body.className += ' blocked-scroll'
                }
            },
            unblockBodyScroll() {
                if (document.body.classList) {
                    document.body.classList.remove('blocked-scroll')
                } else {
                    document.body.className = document.body.className.replace(new RegExp('(^|\\b)' + 'blocked-scroll'.split(' ').join('|') + '(\\b|$)', 'gi'), ' ')
                }
            },
            isHorizontal() {
                return this.menuMode === 'horizontal'
            },
            isSlim() {
                return this.menuMode === 'slim'
            },
            isOverlay() {
                return this.menuMode === 'overlay'
            },
            isStatic() {
                return this.menuMode === 'static'
            },
            isSidebar() {
                return this.menuMode === 'sidebar'
            },
            isDesktop() {
                return window.innerWidth > 991
            },
            isMobile() {
                return window.innerWidth <= 991
            },
            subscribeMqtt() {
                const mqttHook = this.$mqttHook
                console.log('mqttHook', mqttHook)
                const wss = process.env.NODE_ENV == 'production' ? 'wss' : 'ws'
                mqttHook.connect(`${wss}://${process.env.NODE_ENV == 'production' ? process.env.VUE_APP_SERVER_ADDRESS : process.env.VUE_APP_SERVER_IP}:1884`, {
                    clean: false,
                    keepalive: 60,
                    clientId: `mqtt_client_${Math.random().toString(16).substring(2, 10)}`,
                    connectTimeout: 4000,
                })

                mqttHook.registerEvent(
                    'on-connect', // mqtt status: on-connect, on-reconnect, on-disconnect, on-connect-fail
                    (topic, message) => {
                        if (!this.nickname) return
                        console.log('mqtt connected', topic, message)
                        mqttHook.subscribe(
                            [
                                `${this.nickname}/notifications`, // quando l'utente riceve una notifica
                                `/chat/post/+`, //
                                `/chat/topic/+`, //
                                '/chat/post/+/readings', // quando un utente legge un messaggio (il + è il postID)
                                '/chat/post/+/status', // quando lo stato di un messaggio cambia (il + è il postID)
                                '/chat/post/+/comments/readings', // quando un utente legge un commento (il + è il postID)
                            ],
                            2,
                            undefined,
                            (err, granted) => {
                                console.log(`subscribed to ${this.nickname}/notifications`, granted)
                            }
                        )
                    }
                )
            },
            filterMenuByRuolo(menuItems, Ruolo) {
                const filteredMenu = menuItems.filter(item => {
                    if (item.items) {
                        delete item.Ruolo
                        // caso in cui l'elemento è un gruppo di menu
                        item.items = this.filterMenuByRuolo(item.items, Ruolo)
                        return item.items.length > 0
                    } else {
                        delete item.Ruolo
                        if (!routePermissionsMap[item.to]) return true
                        return routePermissionsMap[item.to].some(r => r === Ruolo)
                    }
                })
                return filteredMenu
            },
        },
        created() {
            if (this.ruolo) {
                this.menu = this.filterMenuByRuolo(this.menu, this.ruolo)
            }
        },
        mounted() {
            this.subscribeMqtt()
        },

        computed: {
            layoutContainerClass() {
                return [
                    'layout-wrapper',
                    {
                        'layout-static': this.menuMode === 'static',
                        'layout-overlay': this.menuMode === 'overlay',
                        'layout-overlay-active': this.overlayMenuActive,
                        'layout-slim': this.menuMode === 'slim',
                        'layout-horizontal': this.menuMode === 'horizontal',
                        'layout-active': this.menuActive,
                        'layout-mobile-active': this.staticMenuMobileActive,
                        'layout-sidebar': this.menuMode === 'sidebar',
                        'layout-sidebar-static': this.menuMode === 'sidebar' && this.sidebarStatic,
                        'layout-static-inactive': this.staticMenuDesktopInactive && this.menuMode === 'static',
                        'p-ripple-disabled': this.$primevue.config.ripple === false,
                    },
                ]
            },
            token() {
                const token = localStorage.getItem('token')
                if (token) return VueJwtDecode.decode(localStorage.getItem('token'))
                return null
            },
            nickname() {
                return this.token?.nickname
            },
            nome() {
                return this.token?.nome
            },
            cognome() {
                return this.token?.cognome
            },
            ruolo() {
                return this.token?.ruolo
            },
            sede() {
                return this.token?.sede
            },
            email() {
                return this.token?.email
            },
        },
        components: {
            AppTopBar,
            AppMenu,
            AppRightMenu,
            AppFooter,
            //AppBreadcrumb,
            AppConfig,
        },
    }
</script>
<style>
    .layout-main-content {
        padding-bottom: 0 !important;
    }

    /* Rich text editor */
    .p-editor-container .p-editor-content .ql-editor {
        background-color: rgb(245, 245, 245) !important;
    }
    .p-chips-multiple-container{
        width: 100% !important;
    }
</style>