import { DateTime } from 'luxon';

function formatDateSql(date) {
    const dt = DateTime.fromISO(date);
    return dt.isValid ? dt.toFormat('dd/MM/yyyy') : '';
}

function formatDateTimeSql(date) {
    const dt = DateTime.fromISO(date);
    return dt.isValid ? dt.toFormat('dd/MM/yyyy HH:mm:ss') : '';
}

function formatCurrency(value) {
    return value.toLocaleString('it-IT', { style: 'currency', currency: 'EUR' });
}

function dateFormat(d) {
    if (!d) return '';
    return new Date(d).toLocaleDateString();
}

export default { formatDateSql, formatDateTimeSql, formatCurrency, dateFormat}