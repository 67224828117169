<template>
    <div class="exception-body accessdenied">
        <div class="exception-panel">
            <h1>Accesso</h1>
            <h3>negato</h3>
            <p>Non sei autorizzato a visualizzare questa pagina.</p>
            <router-link to="/">
                <Button type="button" label="Torna alla pagina iniziale"></Button>
            </router-link>
        </div>
        <div class="exception-footer">
            <img :src="'layout/images/logo-' + color + '.png'" class="exception-logo" />
            <img :src="'layout/images/appname-' + color + '.png'" class="exception-appname" />
        </div>
    </div>
</template>

<script>
export default {
    computed: {
        color() {
            if (this.$appState.colorScheme === 'light') return 'dark';
            return 'light';
        },
    },
};
</script>

<style scoped>
</style>
