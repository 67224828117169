import axios from "axios";
const http = process.env.NODE_ENV == 'production' ? 'https' : 'http';

const instance = axios.create({
  baseURL: `${http}://${process.env.NODE_ENV == 'production' ? process.env.VUE_APP_SERVER_ADDRESS : process.env.VUE_APP_SERVER_IP}`
//  baseURL: `${http}://10.0.10.44:1880`
});

instance.interceptors.request.use( (req) => {
    const jwt = localStorage.getItem("token");
    if (jwt) {
      req.headers.authorization = 'Bearer ' + jwt;
    }
    return req;
  });

export default instance;